import * as React from 'react'
import instagram from '../../img/social/instagram.svg'
import threads from '../../img/social/threads.svg'

import Layout from '../../components/Layout'

export default class LinkPage extends React.Component {
  render() {
    return (
      <Layout>
        <div
          className="full-width-image-container "
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('/img/header-background.webp')`,
          }}
        >
          <h1
            style={{
              color: 'white',
              padding: '1rem',
            }}
          >
            Links
          </h1>
        </div>
        <section className="link-list">
          <div className='nick-links'>
            <h2>Nick</h2>
            <a href="https://instagram.com/meincken" target="_blank" rel="noreferrer">
              <img src={instagram} alt="Instagram" />
              meincken
            </a>
            <a href="https://threads.net/@meincken" target="_blank" rel="noreferrer">
              <img src={threads} alt="Threads" />
              @meincken
            </a>
          </div>
          <div className='gayle-links'>
            <h2>Gayle</h2>
            <a href="https://www.instagram.com/gunit606" target="_blank" rel="noreferrer">
              <img src={instagram} alt="Instagram" />
              gunit606
            </a>
          </div>
        </section>
      </Layout>
    )
  }
}
